import React from "react";
import { Paper, TextField, withStyles, IconButton } from "@material-ui/core";
import Table from "./Table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SearchIcon from "@material-ui/icons/Search";
import DataRanger from "./DataRanger/DataRanger";
import AddDialogForm from "./AddDialogForm";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DialogAttachament from "./DialogAttachment";
import DialogShare from "./DialogShare";
import { format } from "date-fns";

class Forms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unidades: [],
      addForm: false,
      addAttach: false,
      addShare: false,
      form_id: props.form_id,
      form_layout_json: [],
      id_data_form: null,
      fromDate: format(new Date(), "yyyy-MM-dd"),
      toDate: format(new Date(), "yyyy-MM-dd"),
      data_filter: "",
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    // this.searchForms()
  }

  searchForms = () => {
    this.myRef.current.searchForms(
      this.state.data_filter,
      this.state.fromDate,
      this.state.toDate
    );
    // this.setState({ loading: true })
    // api.get(`/layouts/${this.state.form_id}`).then(resp => {
    //     this.setState({ form_layout_json: JSON.parse(resp.data) })
    // })
  };
  handleCloseSaveFile = (file) => {
    this.setState({
      addForm: false,
      addAttach: false,
      id_data_form: null,
      addShare: false,
    });
    // this.myRef.current.getDataForms()
    this.myRef.current.addFiles(file);
  };
  handleCloseSave = () => {
    this.setState({
      addForm: false,
      addAttach: false,
      id_data_form: null,
      addShare: false,
    });
    this.myRef.current.getDataForms();
  };
  handleCloseSaveMonitor = () => {
    this.myRef.current.setMonitor(this.state.id_data_form);
    this.setState({
      addForm: false,
      addAttach: false,
      id_data_form: null,
      addShare: false,
    });
  };
  handleCloseSaveVerificador = () => {
    this.myRef.current.setVerificador(this.state.id_data_form);
    this.setState({
      addForm: false,
      addAttach: false,
      id_data_form: null,
      addShare: false,
    });
  };
  onClickData = (inicio, fim, data) => {
    this.setState({
      fromDate: inicio == "Invalid date" ? "" : inicio,
      toDate: fim == "Invalid date" ? "" : fim,
    });
  };
  handleCloseFile = () => {
    this.setState({ addAttach: false, id_data_form: null });
    this.myRef.current.removeRows();
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <IconButton
          aria-label="delete"
          form_id={this.state.form_id}
          className={classes.margin}
          size="large"
          onClick={() => this.props.close()}
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <Paper style={{ margin: "5px" }}>
          {this.state.addForm && (
            <AddDialogForm
              form_id={this.state.form_id}
              id_data_form={this.state.id_data_form}
              handleCloseSave={this.handleCloseSave}
              handleCloseSaveMonitor={this.handleCloseSaveMonitor}
              handleCloseSaveVerificador={this.handleCloseSaveVerificador}
              handleClose={() =>
                this.setState({ addForm: false, id_data_form: null })
              }
              name={this.props.labelForm}
            />
          )}
          {this.state.addAttach && (
            <DialogAttachament
              form_id={this.state.form_id}
              id_data_form={this.state.id_data_form}
              handleCloseSave={this.handleCloseSaveFile}
              handleClose={this.handleCloseFile}
            />
          )}
          {this.state.addShare && (
            <DialogShare
              form_id={this.state.form_id}
              handleCloseSave={this.handleCloseSave}
              handleClose={() => this.setState({ addShare: false })}
            />
          )}
          <div className={classes.divFormSearch}>
            <div className={classes.dataranger}>
              <DataRanger onClick={this.onClickData} />
            </div>
            <div>
              {/* <FormControl style={{ width: '150px', margin: '10px', fontStretch: 'semi-condensed' }} >
                            <InputLabel id="demo-simple-select-label">Filtro</InputLabel>
                            <Select
                                name="selected_status"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={this.handleChangeFiltro}
                                value={this.state.filtro}
                            >
                                <MenuItem style={{ fontStretch: 'semi-condensed' }} value={null}>None</MenuItem>
                                <MenuItem style={{ fontStretch: 'semi-condensed' }} value={0}>Sem Anexo</MenuItem>
                                <MenuItem style={{ fontStretch: 'semi-condensed' }} value={1}>Com Anexo</MenuItem>
                                <MenuItem style={{ fontStretch: 'semi-condensed' }} value={2}>Com Laudo</MenuItem>
                                <MenuItem style={{ fontStretch: 'semi-condensed' }} value={3}>Sem Laudo</MenuItem>
                            </Select>
                        </FormControl> */}
              <TextField
                label={"Filtro"}
                value={this.state.data_filter}
                // variant="outlined"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => this.setState({ data_filter: e.target.value })}
              />
            </div>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Fab
                  aria-label="search"
                  color="primary"
                  type="submit"
                  size="small"
                  onClick={this.searchForms}
                >
                  {this.state.success ? <CheckIcon /> : <SearchIcon />}
                </Fab>
                {this.state.loading && (
                  <CircularProgress size={48} className={classes.fabProgress} />
                )}
              </div>
            </div>
          </div>
        </Paper>
        <div>
          <Table
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            data_filter={this.state.data_filter}
            add={() => this.setState({ addForm: true })}
            attach={(id) =>
              this.setState({ addAttach: true, id_data_form: id })
            }
            edit={(id) => this.setState({ addForm: true, id_data_form: id })}
            share={(id) => this.setState({ addShare: true })}
            form_id={this.state.form_id}
            ref={this.myRef}
            name={this.props.labelForm}
          />
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  typegraphy: {
    backgroundColor: "#0078F0",
    color: "#fff",
    borderRadius: "4px",
    // fontWeight: 'bold',
    padding: "5px",
    textAlign: "center",
    fontStretch: "semi-condensed",
    fontSize: "16px",
  },
  divFormSearch: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "2px",
  },
  divNovoExame: {
    display: "flex",
    marginBottom: "10px",
    marginTop: "10px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -4,
    left: -4,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },
  dataranger: {
    textAlign: "center",
  },
});

export default withStyles(styles)(Forms);

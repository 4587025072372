import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core";
import "../styles/dropzone.css";
import NewUser from "../cadastros/UserShare/newUser";
import AddIcon from "@material-ui/icons/Add";
import api from "../../service/api";

class Sharing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addUser: false,
      users: [],
      userSelected: "",
      form_id: props.form_id,
      layout_id: null,
    };
  }

  getUser = () => {
    const url = window.location.hostname;
    var url_split = url.split(".");
    api.get(`/getDomain?domain=${url_split[0]}`).then((resp_enterprise) => {
      var enterprise_id = resp_enterprise.data.data.result.id;
      api
        .get("/user-share", {
          params: {
            enterprise: enterprise_id,
          },
        })
        .then((resp) => {
          this.setState({ users: resp.data });
        });
    });
  };
  getFormsLayout = () => {
    this.setState({ loading: true });
    api
      .get("/layout/type", {
        params: {
          form_id: this.state.form_id,
        },
      })
      .then(async (resp) => {
        await this.setState({ layout_id: resp.data.layout });
      });
  };
  handleClose = async () => {
    await this.props.handleClose();
  };

  componentDidMount = async () => {
    await this.getFormsLayout();
    await this.getUser();
  };

  handleSalvar = async () => {
    api
      .post("/data", {
        data: JSON.stringify([]),
        layout_id: this.state.layout_id,
        user_id: 1,
      })
      .then((resp) => {
        api
          .post("/sharing", {
            user: this.state.userSelected,
            data_forms: resp.data.data.result.id,
          })
          .then(async (resp_share) => {
            try {
              var url = `http://share.digitalbpf.com.br/?form=${this.state.form_id}&sharing=${resp_share.data.id}&data_form=${resp.data.data.result.id}`;
              this.copyToClipboard(url);
              window.open(url);
              // await navigator.clipboard.writeText(`http://198.199.89.13:3001/?form=${this.state.form_id}&sharing=${resp_share.data.id}&data_form=${resp.data.data.result.id}`);
            } catch (e) {
              console.log(e);
            }
            this.props.handleCloseSave();
          });
      });
  };
  copyToClipboard = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      this.unsecuredCopyToClipboard(content);
    }
  };
  unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  newUserShare = () => {
    this.setState({ addUser: true });
  };
  saveUser = () => {
    this.getUser();
    this.setState({ addUser: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.addUser && (
          <NewUser
            close={() => this.setState({ addUser: false })}
            save={this.saveUser}
          />
        )}
        <Dialog
          // style={{ borderRadius: '10px', zIndex: "10000", }}
          fullWidth={true}
          maxWidth={"sm"}
          open={true}
          onClose={() =>
            this.setState({
              alert: true,
              type: "error",
              text: "Salve a assiantura antes de sair",
              temp: 1000,
              trava: true,
            })
          }
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle
            className={classes.DialogTitle}
            id="max-width-dialog-title"
          >
            Adicionar compartilhamento
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Usuário Compartilhado
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.userSelected}
                onChange={(e) => {
                  this.setState({ userSelected: e.target.value });
                }}
                inputProps={{
                  name: "group-sentence",
                  id: "group-sentence-native-simple",
                }}
              >
                {this.state.users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              aria-label="add"
              size="small"
              onClick={() => this.newUserShare()}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
            <div className={classes.Divbutons}>
              <Button
                onClick={() => this.handleClose()}
                className={classes.button}
              >
                Voltar
              </Button>
              <Button
                disabled={this.state.desabilitado}
                onClick={() => this.handleSalvar()}
                className={classes.button}
              >
                Salvar
              </Button>
            </div>
            {/* {this.state.alert && this.alerta()} */}
          </DialogContent>
          {/* {this.state.trava == true && <CircularProgress size={"6em "} className={classes.loading} />} */}
        </Dialog>
      </div>
    );
  }
}
const useStyles = (theme) => ({
  dialogContent: {
    padding: "8px 0px 0px 24px",
    "& .MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  DialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "21.09px",
      color: "#0D3C61",
    },
  },
  subTitle: {
    color: "#0078F0",
    fontSize: "12px",
  },
  Divbutons: {
    textAlign: "end",
    marginTop: "2em",
    marginBottom: "0.5em",
  },
  button: {
    fontSize: "10px",
    color: "#0078F0",
    marginRight: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(2),
  },
  formControl: {
    width: "200px",
    margin: "10px",
  },
  TextField2: {
    width: "200px",
    margin: "10px",
  },
  titleAndAddIcon: {
    display: "flex",
    justifyContent: "space-between",
  },
  divFormControl: {
    display: "flex",
    justifyContent: "space-between",
  },
  // loading: {
  //     position: "absolute",
  //     zIndex: 1000000,
  //     display: "flex",

  // }
});

export default withStyles(useStyles)(Sharing);

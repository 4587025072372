import React from "react";
import MaterialTable from "material-table";
import api from "../../service/api";
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Table,
  IconButton,
  Typography,
} from "@material-ui/core";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { Lightbox } from "react-modal-image";
import ConfirmDialog from "../alertas/Confirm";
import PrintDialog from "../alertas/Print";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { formatInTimeZone, format, utcToZonedTime } from 'date-fns-tz';
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BPF_SERVER_URL = process.env.REACT_APP_BPF_SERVER_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export default class TableOs extends React.Component {
  constructor(props) {
    super(props);
    const url = window.location.hostname;
    var url_splt = url.split(".");
    this.state = {
      data: [
        // {
        //     'created_at': "06/11/2023",
        //     "user": "Leandro",
        //     "id": 1
        // },
      ],
      loading: false,
      columns: [
        { title: "Código", field: "id" },
        { title: "Data", field: "date" },
        { title: "Identificador", field: "identifier" },
        { title: "Responsavel", field: "user" },
        { title: "Verificador", field: "checker" },
        { title: "Monitor", field: "supervisor" },
        { title: "Compartilhado", field: "user_share" },
        { title: "Data Compartilhamento", field: "date_sharing" },
        { title: "Data Preenchimento", field: "date_complete" },
        { title: "Id Sharing", field: "sharing_id", hidden: true },
        {
          title: "Checkin", field: "checkin", hidden: url_splt[0] != "heineken", render: rowData => {
            if (rowData.checkin) {
              const utcDate = new Date(rowData.checkin.replace(' ', 'T') + 'Z'); // Exemplo de data em UTC
              const localDate = utcDate.toLocaleString(); // Converte para o horário local
              return localDate;
            }
          }
        },
      ],
      form_id: props.form_id,
      fromDate: props.fromDate,
      toDate: props.fromDate,
      data_filter: props.data_filter,
      open: false,
      filename_open: "",
      row: null,
      dataFormId: null,
      openPrintDialog: false,
      permissions: [],
    };
  }

  componentDidMount() {
    this.getDataForms();
    this.getPermissions();
  }

  getPermissions = () => {
    var permissions = JSON.parse(localStorage.getItem("permissions_bpf"));
    this.setState({ permissions });
  };

  searchForms = async (data_filter, fromDate, toDate) => {
    await this.setState({
      data_filter: data_filter,
      fromDate: fromDate,
      toDate: toDate,
    });

    this.getDataForms();
  };
  setMonitor = (data_form_id) => {
    this.setState({ loading: true });
    var new_data = this.state.data;
    var index = this.state.data.findIndex(
      (data_form) => data_form.id == data_form_id
    );
    let name = CryptoJS.AES.decrypt(Cookies.get('name_bpf'), SECRET_KEY).toString(CryptoJS.enc.Utf8)
    new_data[index].supervisor = name;
    this.setState({ data: new_data, loading: false });
  };
  setVerificador = (data_form_id) => {
    this.setState({ loading: true });
    var new_data = this.state.data;
    var index = this.state.data.findIndex(
      (data_form) => data_form.id == data_form_id
    );
    let name = CryptoJS.AES.decrypt(Cookies.get('name_bpf'), SECRET_KEY).toString(CryptoJS.enc.Utf8)
    new_data[index].checker = name;
    this.setState({ data: new_data, loading: false });
  };
  getDataForms = () => {
    this.setState({ loading: true });
    // api.get('/data-form/layout', {
    api
      .get("/data-form/search", {
        params: {
          form_id: this.state.form_id,
          data: this.state.data_filter,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        },
      })
      .then((response) => {
        this.setState({ data: response.data, loading: false });
      });
  };

  addFiles = (file) => {
    this.setState({ loading: true });
    var new_data = this.state.data;
    var index = this.state.row.tableData.id;
    new_data[index].files.push(file);
    this.setState({ data: new_data, loading: false, row: null });
  };
  removeFiles = (indexFile) => {
    this.setState({ loading: true });
    var new_data = this.state.data;
    var index = this.state.row.tableData.id;
    new_data[index].files[indexFile].status = "0";
    this.setState({
      data: new_data,
      loading: false,
      row: null,
      indexFile: null,
    });
  };
  modalView = (filename) => {
    this.setState({ open: true, filename_open: filename });
  };
  removeRows = () => {
    this.setState({ row: null });
  };
  closeLightbox = () => {
    this.setState({ open: false });
  };
  downloadImagem = async (nomeArquivo) => {
    try {
      window.open(
        `${BPF_SERVER_URL}/downloadFile?filename=${nomeArquivo}`
      );
    } catch (error) {
      console.error("Erro ao baixar a imagem:", error);
    }
  };
  openShare = (rowData) => {
    var url = `http://share.digitalbpf.com.br/?form=${this.state.form_id}&sharing=${rowData.sharing_id}&data_form=${rowData.id}`;
    window.open(url);
  };
  deleteImage = (id, indexFile) => {
    this.setState({
      idDelete: id,
      indexFile: indexFile,
      openDialogConfirmDelete: true,
      title: "Atenção",
      body: "Deseja deletar o arquivo?",
    });
  };
  handleDelete = () => {
    api
      .delete(`/filesDataForm/${this.state.idDelete}`)
      .then((resp) => {
        this.setState({ idDelete: null, openDialogConfirmDelete: false });
        this.removeFiles(this.state.indexFile);
      })
      .catch((error) => {
        this.setState({ idDelete: null, openDialogConfirmDelete: false });
      });
  };
  handleClose = () => {
    this.setState({ idDelete: null, openDialogConfirmDelete: false });
  };
  handlePrintV = () => {
    this.state.dataFormId != null &&
      window.open(
        `${BPF_SERVER_URL}/generate-pdf/${this.state.dataFormId}`
      );
    this.setState({ dataFormId: null, openPrintDialog: false });
  };
  handlePrintH = () => {
    this.state.dataFormId != null &&
      window.open(
        `${BPF_SERVER_URL}/generate-pdf/${this.state.dataFormId}?pgOrientation=landscape`
      );
    this.setState({ dataFormId: null, openPrintDialog: false });
  };
  render() {
    return (
      <div>
        {this.state.open && (
          <Lightbox
            // medium={urlToLargeImageFile}
            large={`${BPF_SERVER_URL}/showFile?filename=${this.state.filename_open}`}
            alt={this.state.filename_open}
            onClose={this.closeLightbox}
          />
        )}
        {this.state.openDialogConfirmDelete && (
          <ConfirmDialog
            title={this.state.title}
            body={this.state.body}
            handleCloseConfirmT={this.handleDelete}
            handleCloseConfirmF={this.handleClose}
          />
        )}
        {this.state.openPrintDialog && (
          <PrintDialog
            handleCloseConfirmH={this.handlePrintH}
            handleCloseConfirmV={this.handlePrintV}
          />
        )}

        <MaterialTable
          isLoading={this.state.loading}
          columns={this.state.columns}
          data={this.state.data}
          title={this.props.name}
          options={{ search: false }}
          actions={[
            {
              icon: "add",
              tooltip: "Add",
              isFreeAction: true,
              onClick: () => this.props.add(),
            },
            {
              icon: "share",
              tooltip: "Compartilhar",
              isFreeAction: true,
              onClick: () => this.props.share(),
            },
            {
              icon: "edit",
              tooltip: "Editar Dados",
              onClick: (event, rowData) => this.props.edit(rowData.id),
              // disabled: this.state.permissions?.name === "OPERADOR",
            },
            (rowData) => ({
              icon: "description",
              tooltip: "PDF",
              onClick: (event, rowData) => {
                const url = window.location.hostname;
                var url_splt = url.split(".");
                if (url_splt[0] == "sbr") {
                  this.setState({
                    dataFormId: rowData.id,
                    openPrintDialog: true,
                  });
                } else {
                  window.open(
                    `${BPF_SERVER_URL}/generate-pdf/${rowData.id}`
                  );
                }
              },
              disabled:
                rowData.date_complete == null && rowData.date_sharing != null,
            }),
            {
              icon: "attach_file",
              tooltip: "Anexos",
              onClick: (event, rowData) => {
                this.setState({ row: rowData });
                this.props.attach(rowData.id);
              },
            },

            (rowData) => ({
              icon: "share",
              tooltip: "Link Compartilhado",
              onClick: (event, rowData) => this.openShare(rowData),
              disabled: rowData.date_sharing == null,
            }),
          ]}
          detailPanel={[
            {
              render: (rowData) => {
                return (
                  <Paper
                    margin={1}
                    style={{
                      boxShadow: "1px 3px 1px #ddd",
                      padding: "10px",
                      margin: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      component="div"
                      style={{
                        fontSize: "1rem",
                        color: "#fff",
                        backgroundColor: "#256F44",
                        textAlign: "center",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      INFORMAÇÕES DOS ARQUIVOS
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Nome do Arquivo</TableCell>
                          <TableCell align="center">Data Criação</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {rowData.files.map((file, indexFile) => {
                          return (
                            <TableRow key={1}>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {file.filename}
                              </TableCell>
                              <TableCell align="center">
                                {file.created_at}
                              </TableCell>
                              <TableCell align="center">
                                {file.status == "1" ? "Ativo" : "Inativo"}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  disabled={file.status == "0"}
                                  aria-label="view"
                                  onClick={() => this.modalView(file.filename)}
                                >
                                  <ImageSearchIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  disabled={file.status == "0"}
                                  aria-label="view"
                                  onClick={() =>
                                    this.downloadImagem(file.filename)
                                  }
                                >
                                  <GetAppIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  disabled={file.status == "0"}
                                  aria-label="view"
                                  onClick={() => {
                                    this.setState({ row: rowData });
                                    this.deleteImage(file.id, indexFile);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

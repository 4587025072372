import * as React from "react";
import { Admin, Resource, usePermissions } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import jsonServerProvider from "ra-data-json-server";
import authprovider from "./authprovide";
import CadastroUsuario from "./components/cadastros/Usuario";
import CadastroEmpresa from "./components/cadastros/Enterprise";
import { Layout } from "./components/Layout";
import MyLoginPage from "./components/Layout/Login";
import FormLayout from "./components/form_layout";
// import Folder from './components/folder';
import QrCode from "./components/qrforms";

const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#256F44",
      light: "#1d118c",
    },
    secondary: {
      light: "#01579B",
      main: "#01579B",
    },
    default: {
      light: "#DC004E",
      main: "#DC004E",
    },
  },
  typography: {
    fontFamily: ["Cambay", "sans-serif"].join(","),
  },
});

const initialState = {
  admin: { ui: { sidebarOpen: true, viewVersion: 0 } },
};

function App() {
  const url = window.location.hostname;
  var url_splt = url.split(".");
  return (
    <Admin
      initialState={initialState}
      theme={theme}
      loginPage={MyLoginPage}
      dataProvider={dataProvider}
      authProvider={authprovider}
      // dashboard={Dasboard}
      layout={Layout}
    >
      {(permissions) => {
        var permission = JSON.parse(permissions);
        if (url_splt[0] === "sementestropical") {
          return [
            <Resource
              name="pop02"
              list={FormLayout}
              options={{ label: "Sementes Tropical", type: "2" }}
            />,
            permission?.name == "SUPORTE" ||
              permission?.name == "ADMINISTRADOR" ? (
              <Resource
                name="Usuario"
                list={CadastroUsuario}
                options={{ label: "Usuario" }}
              />
            ) : null,
            permission?.name == "SUPORTE" ? (
              <Resource
                name="Empresa"
                list={CadastroEmpresa}
                options={{ label: "Empresa" }}
              />
            ) : null,
          ];
        } else {
          return [
            url_splt[0] != "nutripura" ? (
              <Resource
                name="pop01"
                list={FormLayout}
                options={{ label: url_splt[0] == "heineken" ? "PORTARIA IND/REG" : "POP 01", type: "1" }}
              />
            ) : null,
            url_splt[0] != "nutripura" ? (
              <Resource
                name="pop02"
                list={FormLayout}
                options={{ label: url_splt[0] == "heineken" ? "CD" : "POP 02", type: "2" }}
              />
            ) : null,
            <Resource
              name="pop03"
              list={FormLayout}
              options={{ label: url_splt[0] == "heineken" ? "LABORATÓRIO" : "POP 03", type: "3" }}
            />,
            <Resource
              name="pop04"
              list={FormLayout}
              options={{ label: url_splt[0] == "heineken" ? "OFICINA" : "POP 04", type: "4" }}
            />,
            url_splt[0] != "nutripura" ? (
              <Resource
                name="pop05"
                list={FormLayout}
                options={{ label: url_splt[0] == "heineken" ? "PORTARIA PRINCIPAL" : "POP 05", type: "5" }}
              />
            ) : null,
            <Resource
              name="pop06"
              list={FormLayout}
              options={{ label: url_splt[0] == "heineken" ? "AUDITÓRIO" : "POP 06", type: "6" }}
            />,
            <Resource
              name="pop07"
              list={FormLayout}
              options={{ label: url_splt[0] == "heineken" ? "VIVÊNCIA" : "POP 07", type: "7" }}
            />,
            <Resource
              name="pop08"
              list={FormLayout}
              options={{ label: url_splt[0] == "heineken" ? "AMBULATÓRIO" : "POP 08", type: "8" }}
            />,
            <Resource
              name="pop09"
              list={FormLayout}
              options={{ label: url_splt[0] == "heineken" ? "RH" : "POP 09", type: "9" }}
            />,
            url_splt[0] == "nutripura" || url_splt[0] == "heineken" ? (
              <Resource
                name="pop10"
                list={FormLayout}
                options={{ label: url_splt[0] == "heineken" ? "ALMOXARIFADO" : "POP 10", type: "10" }}
              />
            ) : null,
            // url_splt[0] == "nutripura" ? <Resource name="pop11" list={FormLayout} options={{ label: 'POP 11', type: '11' }} /> : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop12"
                list={FormLayout}
                options={{ label: "POP 12", type: "12" }}
              />
            ) : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop13"
                list={FormLayout}
                options={{ label: "POP 13", type: "13" }}
              />
            ) : null,
            // url_splt[0] == "nutripura" ? <Resource name="pop14" list={FormLayout} options={{ label: 'POP 14', type: '14' }} /> : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop15"
                list={FormLayout}
                options={{ label: "POP 15", type: "15" }}
              />
            ) : null,
            // url_splt[0] == "nutripura" ? <Resource name="pop16" list={FormLayout} options={{ label: 'POP 16', type: '16' }} /> : null,
            // url_splt[0] == "nutripura" ? <Resource name="pop17" list={FormLayout} options={{ label: 'POP 17', type: '17' }} /> : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop18"
                list={FormLayout}
                options={{ label: "POP 18", type: "18" }}
              />
            ) : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop19"
                list={FormLayout}
                options={{ label: "POP 19", type: "19" }}
              />
            ) : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop23"
                list={FormLayout}
                options={{ label: "POP 23", type: "23" }}
              />
            ) : null,
            url_splt[0] == "nutripura" ? (
              <Resource
                name="pop24"
                list={FormLayout}
                options={{ label: "Segurança do Trabalho", type: "24" }}
              />
            ) : null,
            permission?.name == "SUPORTE" ||
              permission?.name == "ADMINISTRADOR" ? (
              <Resource
                name="Usuario"
                list={CadastroUsuario}
                options={{ label: "Usuario" }}
              />
            ) : null,
            permission?.name == "SUPORTE" ? (
              <Resource
                name="Empresa"
                list={CadastroEmpresa}
                options={{ label: "Empresa" }}
              />
            ) : null,
            // permission?.name == "SUPORTE" ? (
            //   <Resource
            //     name="Folder"
            //     list={Folder}
            //     options={{ label: "Pastas" }}
            //   />
            // ) : null,
            permission?.name == "SUPORTE" ||
              permission?.name == "ADMINISTRADOR" ? (
              <Resource
                name="QrCode"
                list={QrCode}
                options={{ label: "QR Code" }}
              />
            ) : null,
          ];
        }
      }}
    </Admin>
  );
}

export default App;
